// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-taggroup-layout-jsx": () => import("./../src/components/taggroup-layout.jsx" /* webpackChunkName: "component---src-components-taggroup-layout-jsx" */),
  "component---src-components-posts-page-layout-jsx": () => import("./../src/components/posts-page-layout.jsx" /* webpackChunkName: "component---src-components-posts-page-layout-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */)
}

